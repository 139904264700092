.awssld__controls button {
    color: #ff0000;
    /* Change this to your desired arrow color */
}

.awssld__controls button .awssld__control {
    fill: #ff0000;
    /* Change this to your desired arrow color */
}
.aws-btn {
    --slider-height-percentage: 60%;
    --slider-transition-duration: 700ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 6px;
    --organic-arrow-height: 19px;
    --organic-arrow-color: red;
    --control-button-width: 5%;
    --control-button-height: 37%;
    --control-button-background: transparent;
    --control-bullet-color: #fafafa;
    --control-bullet-active-color: #d5d5d5;
    --loader-bar-color: transparent;
    --loader-bar-height: 8px;
}